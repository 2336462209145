// Styles
import { AboutContainer } from './styles';

const About = () => {
  return (
    <AboutContainer>
      <main>
        <p>...</p>
      </main>
    </AboutContainer>
  );
};

export default About;
